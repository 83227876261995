<template>
  <div class="app-container" v-loading="loading">
    <div class="top_box">
      <el-form :inline="true" :model="queryParams" class="demo-form-inline">
        <el-form-item label="类型">
          <el-select
            clearable
            v-model="queryParams.event_type"
            placeholder="请选择类型"
          >
            <el-option label="创建" value="1"></el-option>
            <el-option label="修改" value="2"></el-option>
            <el-option label="删除" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="bottom_box">
      <div class="content_t">
        <el-table
          :data="tableData"
          style="width: 100%"
          fit
          border
          highlight-current-row
          class="tableList3"
          :header-cell-style="{ background: '#F3F7F9', color: '#909399' }"
          height="100%"
          algin="center"
        >
          <el-table-column prop="id" label="ID" width="100"></el-table-column>
          <el-table-column prop="username" label="操作说明">
            <template slot-scope="scope">
              <span style="color:#67c23a;" v-if="scope.row.event_type == 1">
                创建标签
              </span>
              <span
                style="color:#e6a23c;"
                v-else-if="scope.row.event_type == 2"
              >
                修改标签
              </span>
              <span
                style="color:#f56c6c;"
                v-else-if="scope.row.event_type == 3"
              >
                删除标签
              </span>
              {{
                textChange(scope.row.object_json_repr)
                  ? textChange(scope.row.object_json_repr)[0].fields
                    ? textChange(scope.row.object_json_repr)[0].fields.name
                    : ''
                  : ''
              }}
            </template>
          </el-table-column>

          <el-table-column prop="datetime" label="操作时间"></el-table-column>
        </el-table>
      </div>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="queryParams.page_index"
          :page-size="queryParams.page_size"
          :page-sizes="[10, 20, 50, 100]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { operation_logsApi } from '@/api/log'
import { cacheMixin } from '@/mixins/mixin'
export default {
  name: 'changeLog',
  mixins: [cacheMixin],
  components: {},
  data() {
    return {
      tableData: [],
      queryParams: {
        page_index: 1,
        page_size: 20,
        content_type: 15
      },
      total: 0,

      loading: false
    }
  },
  created() {
    this.queryData()
  },
  methods: {
    queryData() {
      this.loading = true
      operation_logsApi(this.queryParams).then(resp => {
        this.tableData = resp.items
        this.total = resp.count
        this.loading = false
      })
    },
    handleSizeChange(val) {
      // 当每页显示条数发生变化时
      this.queryParams.page_size = val
      this.queryData()
    },
    handleCurrentChange(val) {
      // 当页码发生变化时
      this.queryParams.page_index = val
      this.queryData()
    },

    onSearch() {
      this.page = 1
      this.queryData()
    },
    textChange(str) {
      if (!str || typeof str !== 'string') return ''
      let obj = JSON.parse(str)
      return obj || []
    }
  }
}
</script>

<style lang="scss" scoped>
.top_box {
  width: 100%;
  padding: 10px;
  background: #fff;
  border: 1px solid #e5e5e5;
  box-shadow: 0 1px 0px 0 #d8d9d9;
  border-radius: 5px;
  button.el-button.el-button--primary {
    height: 32px;
    padding: 0 20px;
    // line-height: 32px;
  }
  button.el-button.el-button--success.el-button--mini {
    height: 32px;
    padding: 0 20px;
  }
  .el-form-item {
    margin-bottom: 0;
    .el-input {
      padding: 0 15px;
      width: 250px;
      ::v-deep span.el-input__suffix {
        right: 20px;
      }
    }
    ::v-deep input.el-input__inner {
      height: 32px;
      line-height: 32px;
    }
  }
}

.bottom_box {
  width: 100%;
  height: calc(100vh - 178px);
  margin-top: 10px;
  border: 1px solid #e5e5e5;

  box-shadow: 0 1px 0px 0 #d8d9d9;
  border-radius: 5px;
  padding: 10px;
  .content_t {
    height: calc(100% - 50px);
    margin-top: 10px;
    // overflow: auto;
    ::v-deep td.el-table_1_column_1.el-table__cell {
      text-align: center;
    }
  }
}
</style>
